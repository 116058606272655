var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"prozess-wrapper"},[_c('div',{staticClass:"prozess"},[_c('div',[(!_vm.claimed)?_c('div',[_c('TaskBlocker',{attrs:{"assignee":_vm.data.assignee}})],1):_vm._e(),(!_vm.isLoading)?_c('div',[_c('DisplayContainer',{attrs:{"label":'Statusänderung freigeben: ' + _vm.propertyChangeState}},[_c('div',{staticClass:"margin-bottom-2"},[_vm._v(" Statusänderung durch "+_vm._s(_vm.projectConstructionStateChangeRequest .projectConstructionStateChangeDataDto.user)+" ")]),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.projectConstructionStateChangeRequest.propertyConstructionStateDtos,"single-expand":false,"show-expand":false,"disable-filtering":!_vm.claimed,"disable-sort":!_vm.claimed,"multi-sort":"","footer-props":{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  'items-per-page-text': 'Zeilen pro Seite:',
                  'items-per-page-options': [10, 25, 50, 100, -1]
                }},scopedSlots:_vm._u([{key:"item.address.houseNumber",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.address.houseNumber)+_vm._s(item.address.houseNumberSupplement)+" ")]}},{key:"item.address.zipCode",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.address.zipCode)+" ")]}},{key:"item.address.district",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.address.district)+" ")]}},{key:"item.address.street",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.address.street)+" ")]}},{key:"item.constructionState",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.houseConnectionBuild ? '#6aa84f' : '#9b3232'}},'v-icon',attrs,false),on),[_vm._v(" mdi-home-alert ")])]}}],null,true)},[(item.houseConnectionBuild)?_c('span',[_vm._v("Hausanschluss gebaut")]):_c('span',[_vm._v("Hausanschluss nicht gebaut")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.houseConnectionPatched ? '#6aa84f' : '#9b3232'}},'v-icon',attrs,false),on),[_vm._v(" mdi-home-assistant ")])]}}],null,true)},[(item.houseConnectionPatched)?_c('span',[_vm._v("Haus gepatched")]):_c('span',[_vm._v("Haus nicht gepatched")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.popOnline ? '#6aa84f' : '#9b3232'}},'v-icon',attrs,false),on),[_vm._v(" mdi-transit-connection-variant ")])]}}],null,true)},[(item.popOnline)?_c('span',[_vm._v("POP Online")]):_c('span',[_vm._v("POP nicht Online")])])]}}],null,true)}),_c('v-textarea',{attrs:{"v-model":_vm.rejectionReason,"label":"Anmerkungen","disabled":!_vm.claimed},on:{"input":function($event){_vm.rejectionReason = $event}}})],1),_c('v-col',[_c('DetailMap',{attrs:{"data":_vm.projectConstructionStateChangeRequest.propertyConstructionStateDtos,"projectId":_vm.projectConstructionStateChangeRequest
                    .projectConstructionStateChangeDataDto.projectId}})],1)],1)],1)],1):_vm._e()]),(!_vm.isLoading)?_c('div',{staticClass:"buttons-wrapper"},[_c('v-tooltip',{attrs:{"top":"","disabled":!(_vm.buttonsDisabled || _vm.rejectionReason === '') || !_vm.claimed},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"tooltip-button-wrapper"},'div',attrs,false),on),[_c('v-btn',{attrs:{"disabled":_vm.buttonsDisabled || _vm.rejectionReason === '',"color":"secondary","large":""},on:{"click":function($event){return _vm.completeTask(false)}}},[_vm._v(" Ablehnen ")])],1)]}}],null,false,3918280474)},[(_vm.rejectionReason === '')?_c('span',[_vm._v("Zum Ablehnen ist eine Begründung für den Nutzer "+_vm._s(_vm.projectConstructionStateChangeRequest .projectConstructionStateChangeDataDto.user)+" erforderlich")]):_vm._e()]),_c('v-btn',{attrs:{"disabled":_vm.buttonsDisabled,"color":"primary","large":""},on:{"click":function($event){return _vm.completeTask(true)}}},[_vm._v(" Bestätigen ")])],1):_vm._e()]),(
      _vm.projectConstructionStateChangeRequest &&
        _vm.projectConstructionStateChangeRequest.accepted &&
        _vm.waitingForFinish
    )?_c('v-overlay',[_c('v-progress-circular',{attrs:{"size":120,"color":"primary","indeterminate":""}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }