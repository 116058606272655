<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>
        <div v-if="!isLoading">
          <DisplayContainer
            :label="
              propertyStateChangeRejected
                ? `Liegenschaftsstatusänderung abgelehnt: ${propertyChangeState}`
                : projectStateChangeRejected
                  ? `Projektstatusänderung abgelehnt: ${projectState}`
                  : ''
            "
          >
            <div class="margin-bottom-2">
              Statusänderung abgelehnt von
              {{ rejectedByUser }}
            </div>
            <v-row>
              <v-col>
                <v-data-table
                  :headers="tableHeaders"
                  :items="
                    projectConstructionStateChangeRequest.propertyConstructionStateDtos
                  "
                  :single-expand="false"
                  :show-expand="false"
                  :disable-filtering="!claimed"
                  :disable-sort="!claimed"
                  class="elevation-1"
                  multi-sort
                  :footer-props="{
                    showFirstLastPage: true,
                    showCurrentPage: true,
                    'items-per-page-text': 'Zeilen pro Seite:',
                    'items-per-page-options': [10, 25, 50, 100, -1]
                  }"
                >
                  <template v-slot:[`item.address.houseNumber`]="{ item }">
                    {{ item.address.houseNumber
                    }}{{ item.address.houseNumberSupplement }}
                  </template>
                  <template v-slot:[`item.address.zipCode`]="{ item }">
                    {{ item.address.zipCode }}
                  </template>
                  <template v-slot:[`item.address.district`]="{ item }">
                    {{ item.address.district }}
                  </template>
                  <template v-slot:[`item.address.street`]="{ item }">
                    {{ item.address.street }}
                  </template>
                  <template v-slot:[`item.constructionState`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          :color="
                            item.houseConnectionBuild ? '#6aa84f' : '#9b3232'
                          "
                          v-on="on"
                        >
                          mdi-home-alert
                        </v-icon>
                      </template>
                      <span
                        v-if="item.houseConnectionBuild"
                      >Hausanschluss gebaut</span>
                      <span v-else>Hausanschluss nicht gebaut</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          :color="
                            item.houseConnectionPatched ? '#6aa84f' : '#9b3232'
                          "
                          v-on="on"
                        >
                          mdi-home-assistant
                        </v-icon>
                      </template>
                      <span
                        v-if="item.houseConnectionPatched"
                      >Haus gepatched</span>
                      <span v-else>Haus nicht gepatched</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          :color="item.popOnline ? '#6aa84f' : '#9b3232'"
                          v-on="on"
                        >
                          mdi-transit-connection-variant
                        </v-icon>
                      </template>
                      <span v-if="item.popOnline">POP Online</span>
                      <span v-else>POP nicht Online</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-textarea
                  :value="rejectionReason"
                  label="Anmerkungen"
                  :disabled="true"
                />
              </v-col>
              <v-col>
                <DetailMap
                  :data="
                    projectConstructionStateChangeRequest.propertyConstructionStateDtos
                  "
                  :projectId="
                    projectConstructionStateChangeRequest
                      .projectConstructionStateChangeDataDto.projectId
                  "
                />
              </v-col>
            </v-row>
          </DisplayContainer>
        </div>
      </div>
      <div v-if="!isLoading" class="buttons-wrapper">
        <v-btn
          :disabled="buttonsDisabled"
          color="primary"
          large
          @click="completeTask()"
        >
          Bestätigen
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import NotificationObject from '@/main/NotificationObject.js';
import TaskBlocker from '@/task/TaskBlocker.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import DataDisplay from '@/components/elements/DataDisplay.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
import DetailMap from '@/components/constructionProjects/DetailMap.vue';

import { HTTP } from '@/main/httpClient.js';

export default {
  name: 'ActionRejectStateChange',
  components: {
    TaskBlocker,
    DisplayContainer,
    DataDisplay,
    DetailMap
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    rejectionReason: '',
    projectStateChangeRejected: undefined,
    propertyStateChangeRejected: undefined,
    rejectedByUser: undefined,
    inputsInvalid: false,
    projectConstructionStateChangeRequest: null,
    taskDataLoading: true,
    waitingForFinish: false,
    tableHeaders: [
      { text: 'PLZ', value: 'address.zipCode', width: '80px' },
      { text: 'Ortsteil', value: 'address.district' },
      { text: 'Straße', value: 'address.street' },
      { text: 'Hausnummer', value: 'address.houseNumber' },
      {
        text: 'Baustatus',
        align: 'start',
        value: 'constructionState'
      },
      { text: 'Verträge', value: 'contractList.length' }
    ]
  }),
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    },
    isLoading() {
      return this.taskDataLoading;
    },
    buttonsDisabled() {
      return this.waitingForFinish || this.isLoading || !this.claimed;
    },
    propertyChangeState() {
      const projectConstructionStateChangeDataDto =
        this.projectConstructionStateChangeRequest
          .projectConstructionStateChangeDataDto;
      const newStateProperty = [];
      if (projectConstructionStateChangeDataDto.houseconnectionBuilt) {
        newStateProperty.push('Hausanschluss gebaut');
      }
      if (projectConstructionStateChangeDataDto.housePatched) {
        newStateProperty.push('Haus gepatched');
      }
      if (projectConstructionStateChangeDataDto.popOnline) {
        newStateProperty.push('POP Online');
      }
      return newStateProperty.join(', ');
    },
    projectState() {
      var constructionStartText = '';
      if (
        this.projectConstructionStateChangeRequest
          .projectConstructionStateChangeDataDto.newStateProject ===
        this.projectStateConstant.PROJECT_CONSTRUCTION_STARTED.value
      ) {
        constructionStartText =
          ' am ' +
          this.projectConstructionStateChangeRequest.projectConstructionStateChangeDataDto.constructionStart
            .substring(0, 10)
            .split('-')
            .reverse()
            .join('.');
      }
      return `Projekt 
        ${this.projectConstructionStateChangeRequest.projectConstructionStateChangeDataDto.projectId} ( 
        ${this.projectConstructionStateChangeRequest.projectConstructionStateChangeDataDto.city} - ${this.projectConstructionStateChangeRequest.projectConstructionStateChangeDataDto.district} ) - ${this.projectConstructionStateChangeRequest.projectConstructionStateChangeDataDto.newStateProject} ${constructionStartText}`;
    }
  },
  mounted: function () {
    this.loadTaskData(this.data.id);
  },
  created() {
    this.projectStateConstant = {
      PROJECT_CONSTRUCTION_STARTED: {
        value: 'Bau gestartet'
      }
    };
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    loadTaskData: function (id) {
      HTTP.get(`/task/${id}/variables`)
        .then((resp) => {
          this.projectConstructionStateChangeRequest =
            resp.data.projectConstructionStateChangeRequest.value;
          this.taskDataLoading = false;
          this.projectStateChangeRejected =
            resp.data.projectStateChangeRejected.value;
          this.propertyStateChangeRejected =
            resp.data.propertyStateChangeRejected.value;
          this.rejectionReason = resp.data.stateChangeRejectionReason.value;
          this.rejectedByUser = resp.data.stateChangeRejectedBy.value;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Ein Fehler ist aufgetreten'
            )
          )
        );
    },
    completeTask: function () {
      this.waitingForFinish = true;
      this.projectConstructionStateChangeRequest.verifyUser =
        this.$auth.user().userName;
      this.projectConstructionStateChangeRequest.rejectionReason =
        this.rejectionReason;
      HTTP.post(
        `/projectConstructionState/rejectNotificationConfirm/${this.data.id}`,
        this.projectConstructionStateChangeRequest,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then((resp) => {
          this.showMessage('success', 'Die Aufgabe wurde abgebrochen.');
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch((err) => {
          this.waitingForFinish = false;
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Aufgabe konnte nicht erfolgreich abgeschlossen werden.'
            )
          );
        });
    }
  }
};
</script>
<style scoped>
.buttons-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: var(--goe-spacing-1);
}

.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-0);
}

.group > div:nth-child(2) > div {
  margin-bottom: var(--goe-spacing-2);
}

.group > div:nth-child(2) > div:last-child {
  margin-bottom: var(--goe-spacing-0);
}

.input-wrapper {
  margin-bottom: var(--goe-spacing-2);
}
</style>
