<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>
        <div v-if="!isLoading">
          <DisplayContainer :label="`Projektstatusänderung freigeben: ${projectState}`">
            <div class="margin-bottom-2">
              Statusänderung durch
              {{
                projectConstructionStateChangeRequest
                  .projectConstructionStateChangeDataDto.user
              }}
            </div>
            <v-row>
              <v-col>
                <v-data-table
                  :headers="tableHeaders"
                  :items="
                    projectConstructionStateChangeRequest.propertyConstructionStateDtos
                  "
                  :single-expand="false"
                  :show-expand="false"
                  class="elevation-1"
                  multi-sort
                  :footer-props="{
                    showFirstLastPage: true,
                    showCurrentPage: true,
                    'items-per-page-text': 'Zeilen pro Seite:',
                    'items-per-page-options': [10, 25, 50, 100, -1]
                  }"
                >
                  <template v-slot:[`item.address.houseNumber`]="{ item }">
                    {{ item.address.houseNumber
                    }}{{ item.address.houseNumberSupplement }}
                  </template>
                  <template v-slot:[`item.address.zipCode`]="{ item }">
                    {{ item.address.zipCode }}
                  </template>
                  <template v-slot:[`item.address.district`]="{ item }">
                    {{ item.address.district }}
                  </template>
                  <template v-slot:[`item.address.street`]="{ item }">
                    {{ item.address.street }}
                  </template>
                  <template v-slot:[`item.constructionState`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          :color="
                            item.houseConnectionBuild ? '#6aa84f' : '#9b3232'
                          "
                          v-on="on"
                        >
                          mdi-home-alert
                        </v-icon>
                      </template>
                      <span v-if="item.houseConnectionBuild">Hausanschluss gebaut</span>
                      <span v-else>Hausanschluss nicht gebaut</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          :color="
                            item.houseConnectionPatched ? '#6aa84f' : '#9b3232'
                          "
                          v-on="on"
                        >
                          mdi-home-assistant
                        </v-icon>
                      </template>
                      <span v-if="item.houseConnectionPatched">Haus gepatched</span>
                      <span v-else>Haus nicht gepatched</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          :color="item.popOnline ? '#6aa84f' : '#9b3232'"
                          v-on="on"
                        >
                          mdi-transit-connection-variant
                        </v-icon>
                      </template>
                      <span v-if="item.popOnline">POP Online</span>
                      <span v-else>POP nicht Online</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-textarea
                  :v-model="rejectionReason"
                  label="Anmerkungen"
                  :disabled="!claimed"
                  @input="rejectionReason = $event"
                />
              </v-col>
              <v-col>
                <DetailMap
                  :data="
                    projectConstructionStateChangeRequest.propertyConstructionStateDtos
                  "
                  :projectId="
                    projectConstructionStateChangeRequest
                      .projectConstructionStateChangeDataDto.projectId
                  "
                />
              </v-col>
            </v-row>
          </DisplayContainer>
        </div>
      </div>
      <div
        v-if="!isLoading"
        class="buttons-wrapper"
      >
        <v-tooltip
          top
          :disabled="!(buttonsDisabled || rejectionReason === '') || !claimed"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              class="tooltip-button-wrapper"
              v-bind="attrs"
              v-on="on"
            >
              <v-btn
                :disabled="buttonsDisabled || rejectionReason === ''"
                color="secondary"
                large
                @click="completeTask(false)"
              >
                Ablehnen
              </v-btn>
            </div>
          </template>
          <span v-if="rejectionReason === ''">Zum Ablehnen ist eine Begründung für den Nutzer
            {{
              projectConstructionStateChangeRequest
                .projectConstructionStateChangeDataDto.user
            }}
            erforderlich</span>
        </v-tooltip>
        <v-btn
          :disabled="buttonsDisabled"
          color="primary"
          large
          @click="completeTask(true)"
        >
          Bestätigen
        </v-btn>
      </div>
    </div>
    <v-overlay v-if="
        projectConstructionStateChangeRequest &&
          projectConstructionStateChangeRequest.accepted &&
          waitingForFinish
      ">
      <v-progress-circular
        :size="120"
        color="primary"
        indeterminate
      >
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import NotificationObject from '@/main/NotificationObject.js';
import TaskBlocker from '@/task/TaskBlocker.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import DataDisplay from '@/components/elements/DataDisplay.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
import DetailMap from '@/components/constructionProjects/DetailMap.vue';

import { HTTP } from '@/main/httpClient.js';

export default {
  name: 'ActionReleaseProjectStateChange',
  components: {
    TaskBlocker,
    DisplayContainer,
    DataDisplay,
    DetailMap
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    inputsInvalid: false,
    projectConstructionStateChangeRequest: null,
    taskDataLoading: true,
    waitingForFinish: false,
    rejectionReason: '',
    tableHeaders: [
      { text: 'PLZ', value: 'address.zipCode', width: '80px' },
      { text: 'Ortsteil', value: 'address.district' },
      { text: 'Straße', value: 'address.street' },
      { text: 'Hausnummer', value: 'address.houseNumber' },
      {
        text: 'Baustatus',
        align: 'start',
        value: 'constructionState'
      },
      { text: 'Verträge', value: 'contractList.length' }
    ]
  }),
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    },
    isLoading() {
      return this.taskDataLoading;
    },
    buttonsDisabled() {
      return this.waitingForFinish || this.isLoading || !this.claimed;
    },
    projectState() {
      var constructionStartText = '';
      if (
        this.projectConstructionStateChangeRequest
          .projectConstructionStateChangeDataDto.newStateProject ===
        this.projectStateConstant.PROJECT_CONSTRUCTION_STARTED.value
      ) {
        constructionStartText =
          ' am ' +
          this.projectConstructionStateChangeRequest.projectConstructionStateChangeDataDto.constructionStart
            .substring(0, 10)
            .split('-')
            .reverse()
            .join('.');
      }
      return (
        'Projekt ' +
        this.projectConstructionStateChangeRequest
          .projectConstructionStateChangeDataDto.projectId +
        ' ( ' +
        this.projectConstructionStateChangeRequest
          .projectConstructionStateChangeDataDto.city +
        ' - ' +
        this.projectConstructionStateChangeRequest
          .projectConstructionStateChangeDataDto.district +
        ' ) - ' +
        this.oldStateProject +
        ' > ' +
        this.newStateProject +
        constructionStartText
      );
    },
    oldStateProject() {
      return this.projectConstructionStateChangeRequest
        .projectConstructionStateChangeDataDto.oldProjectStateDto?.description ?? 'unbekannt';
    },
    newStateProject() {
      return this.projectConstructionStateChangeRequest
        .projectConstructionStateChangeDataDto.newProjectStateDto?.description ?? 'unbekannt';
    }
  },
  mounted: function () {
    this.getTaskData(this.data.id);
  },
  created() {
    this.projectStateConstant = {
      PROJECT_CONSTRUCTION_STARTED: {
        value: 'Bau gestartet'
      }
    };
  },
  methods: {
    getTaskData(id) {
      this.taskDataLoading = true;
      const backendCalls = [];
      backendCalls.push(this.loadTaskData(id));
      Promise.allSettled(backendCalls).then(() => {
        this.taskDataLoading = false;
      });
    },
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    loadTaskData: function (id) {
      return HTTP.get(`/task/${id}/variables`)
        .then((resp) => {
          this.projectConstructionStateChangeRequest =
            resp.data.projectConstructionStateChangeRequest.value;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Ein Fehler ist aufgetreten'
            )
          )
        );
    },
    completeTask: function (accepted) {
      this.projectConstructionStateChangeRequest.verifyUser =
        this.$auth.user().userName;
      this.projectConstructionStateChangeRequest.accepted = accepted;
      this.projectConstructionStateChangeRequest.rejectionReason =
        this.rejectionReason;
      this.waitingForFinish = true;
      HTTP.post(
        `/projectConstructionState/closeReleaseProjectStateChangeTask/${this.data.id}`,
        this.projectConstructionStateChangeRequest,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then((resp) => {
          if (accepted) {
            this.showMessage(
              'success',
              'Die Aufgabe wurde erfolgreich abgeschlossen.'
            );
          } else {
            this.showMessage(
              'success',
              `Der Benutzer ${this.projectConstructionStateChangeRequest.projectConstructionStateChangeDataDto.user} wird über die Ablehung der Statusänderung informiert`
            );
          }
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch((err) => {
          this.waitingForFinish = false;
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Aufgabe konnte nicht erfolgreich abgeschlossen werden.'
            )
          );
        });
    }
  }
};
</script>
<style scoped>
.buttons-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: var(--goe-spacing-1);
}

.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-0);
}

.buttons-wrapper .tooltip-button-wrapper {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-0);
}

.group > div:nth-child(2) > div {
  margin-bottom: var(--goe-spacing-2);
}

.group > div:nth-child(2) > div:last-child {
  margin-bottom: var(--goe-spacing-0);
}

.input-wrapper {
  margin-bottom: var(--goe-spacing-2);
}
</style>
